var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{style:({height: '100%', width: '100%'}),attrs:{"loading":_vm.GetModerationListAction,"type":"card-heading, image@3"}},[(_vm.moderations.length>0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"title-m-bold neutral-900--text",attrs:{"cols":"auto"}},[_vm._v(" История модераций ")]),_c('v-col',{attrs:{"cols":"6","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',[_c('base-text-field',{staticStyle:{"min-width":"225px"},attrs:{"prepend-inner-icon":'$iconify_search-outlined',"placeholder":"Поиск измененных данных","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('base-table',{attrs:{"class-name":"table-moderations","headers":_vm.headers,"items":_vm.filtered_moderations,"item-class":function () { return 'clickable-row'; },"is-custom-header":false,"total-count":_vm.filtered_moderations.length,"word-operations":['модерация', 'модерации', 'модераций'],"pagination":{
            sortBy: 'updated_at',
            descending: 'descending',
          }},on:{"click:row":_vm.openModerationClick},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('date-column',{attrs:{"value":item.updated_at}})]}},{key:"item.fields",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFieldNameList(item))+" ")])]}},{key:"item.user",fn:function(ref){
          var item = ref.item;
return [_c('user-column',{attrs:{"user":item.user,"show-last-activity":""}})]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('moderation-status-label',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openModerationClick(item)}}},[_vm._v(" mdi-chevron-right ")])]}}],null,true)})],1)],1),(_vm.showDialog)?_c('moderation-form',{attrs:{"moderation-id":_vm.moderationId,"program-id":_vm.programId},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}):_vm._e()],1):_c('base-empty-block-page',{attrs:{"title":"Здесь пока ничего нет :’(","description":"Вы ничего не отправляли на модерацию"},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("@/assets/png/settings-dummy.png"),"width":"186px","height":"156px"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }